const AVAILABLE = 'available';
const UPCOMING = 'upcoming';
const COMPLETED = 'completed';
const ENROLLED = 'enrolled';
export default {
    AVAILABLE,
    UPCOMING,
    COMPLETED,
    ENROLLED
};
