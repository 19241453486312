<template>
    <div class="welcome-banner flex-row ai-c jc-sb">
      <div class="welcome-banner__wrapper flex-column w-100">
        <Typography variant="h3" textWeight="400">{{ header }}</Typography>
        <Typography variant="p" textWeight="500">
          Lorem ipsum dolor sit amet consectetur. Sed odio tristique a libero.<br>
          Habitant duis congue nunc etiam malesuada risus. Tellus rhoncus vel est<br>
          arcu. Ornare nisi sed odio id. Eget in vestibulum lorem lectus justo enim.
        </Typography>
        <div class="flex-row">
          <slot name="custom-footer" v-if="hasCustomFooter"></slot>
          <div class="flex-row" v-else>
            <Button :buttonText="buttonLabel" :isActive="mainButtonIsActive" @handle-click="handleEvent"/>
            <slot name="secondary-action"></slot>
          </div>
        </div>
      </div>
      <div class="welcome-banner__img-wrapper w-100 h-100">
        <inline-svg width="100%" :src="require('@/assets/icons/courses-hero-image-2.svg')"></inline-svg>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  name: 'TradePassportBanner',

  emits: ['handle-event'],

  props: {
    hasCustomFooter: Boolean,
    header: String,
    buttonLabel: String,
    mainButtonIsActive: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Typography,
    Button,
  },

  data() {
    return {
      attachments: [],
      previewImages: []
    };
  },

  methods: {
    handleEvent() {
      this.$emit('handle-event');
    }
  },
});
</script>
<style lang="scss" scoped>
.welcome-banner {
  padding: 1.5rem 2rem;
  &__button {
    border: 1px solid #4F55F0;
  }

  &__img-wrapper {
    text-align: end;
    min-height: 180px;

    .img {
      width: 60%;
    }
  }
}
</style>
