<template>
    <div
      class="course-card default-card flex-column jc-sb gap-half"
      :class="{'small-card': isCardSmall}">
        <div class="course-card__content flex-column gap-half">
          <!-- <CourseTimeState time="22:42" v-if="!isCardSmall"/> -->
          <div class="flex-row ai-c jc-sb" v-if="course.isUpcoming">
            <CourseStatusState
            :isEnrolled="hasSavedDate(course)"
            style="max-width: 150px !important"
            :label="getStatusLabel(course)"/>
            <Typography variant="p" textColor="#FFA500">
              {{ getReadableDate(course?.selectedStartDate) }}
            </Typography>
          </div>
          <div class="course-card__image-wrapper w-100">
            <Icon customPath="images" :iconName="course.thumbnail"/>
          </div>
          <div class="course-card__wrapper flex-row ai-c jc-sb">
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="currency-filled-green.svg"
              textColor="#1FB27A"
              :label="$filters.formatToCurrency('220')"
              :labelSpan="course.courseFee === 'FREE' ? course.courseFee : $filters.formatToCurrency(course.courseFee)"
              :crashLabel="true"/>
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="location-filled-blue.svg"
              textColor="#264FD5"
              label="Online & In-Person"/>
          </div>
          <div class="course-card__wrapper flex-column gap-half">
            <Typography variant="h6">
              {{ course.title }}
            </Typography>
            <Typography
              class="ellipsis"
              v-if="!isCardSmall"
              variant="custom"
              textSize="12px"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.50)"
              lineHeight="20px">
                {{ course.about }}
            </Typography>
          </div>
          <!-- <MemberCounter
            v-if="!isCardSmall && members.length"
            :visibleMembers="visibleMembers"
            :remainingMembersCount="remainingMembersCount"/> -->
        </div>
        <div class="course-card__footer flex-column gap-half w-100" v-if="!isCardSmall">
          <Button
            buttonText="view full course details"
            :isActive="true"
            activeColor="#FFF"
            activeFontColor="#4F55F0"
            borderColor="#4F55F0"
            @handle-click="viewCourseDetails"/>
          <Button
            v-if="!hasSavedDate(course)"
            :buttonText="getButtonLabel(course)"
            :isActive="true"
            @click="handleClick(course)"/>
        </div>
        <div class="course-card__footer flex-row ai-c jc-fs w-100" v-if="isCardSmall">
          <ActionItemComponent
            @call-to-action="viewCourseDetails"
            icon="arrow-right.svg"
            iconSize="20"
            label="VIEW FULL COURSE DETAILS"
            :isReverse="true"
            fontColor="#FFA500"/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import ChipState from '@/core/components/ui/ChipState.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
// import CourseTimeState from '@/modules/courses-children/components/CourseTimeState.vue';
// import MemberCounter from '@/modules/courses-children/components/MemberCounter.vue';
import appFilters from '@/filters';
import CourseStatusState from '@/modules/courses-children/components/CourseStatusState.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    Button,
    Typography,
    Icon,
    ActionItemComponent,
    ChipState,
    // CourseTimeState,
    // MemberCounter,
    CourseStatusState
  },

  props: ['isCardSmall', 'course'],

  data() {
    return {
      members: [
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
      ],
    };
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),

    visibleMembers() {
      return this.members.slice(0, 5);
    },
    remainingMembersCount() {
      return Math.max(0, this.members.length - 5);
    }
  },

  methods: {
    ...mapActions(OPPORTUNITY_STORE, ['getCourses', 'setCurrentCourse']),

    viewCourseDetails() {
      this.setCurrentCourse(this.course);
      this.$router.push({
        name: 'course-details',
        params: { courseId: this.course.id },
      });
    },

    hasNoSavedDate(course) {
      return course.isUpcoming && !course.hasSelectedDate;
    },

    hasSavedDate(course) {
      return course.isUpcoming && course.hasSelectedDate;
    },

    getButtonLabel(course) {
      if (this.hasNoSavedDate(course)) {
        return 'select date';
      }
      return 'sign up for course';
    },

    getStatusLabel(course) {
      if (this.hasSavedDate(course)) {
        return "You're Enrolled";
      }
      return "You're Pre-Enrolled";
    },

    getReadableDate(value) {
      let readableDate = '';

      if (value) {
        readableDate = appFilters.formatToDate(value, 'Do MMM YYYY');
      }

      return readableDate;
    },

    handleClick(course) {
      if (this.hasSavedDate(course) || course.isAvailable) {
        // do something
      } else {
        this.$router.push({
          name: 'opportunity-select-spot',
          params: { courseId: this.course.id }
        });
      }
    }
  },
});
</script>
<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 1.6em;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.course-card {
    max-height: 425px;
    min-height: 425px;

    &__image-wrapper {
      max-height: 80px;
      min-height: 80px;
      border-radius: 8px;
      overflow: hidden;
    }
}

.course-card.small-card {
  max-height: 228px;
  min-height: 228px;
}
</style>
